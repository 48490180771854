import { toast } from 'react-toastify';
import { ErrorToast } from '@nordictrustee/nt-ui-library';
import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import * as URL from 'router/url';

const axiosInstance = axios.create({
  baseURL: '/api',
});

axiosInstance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    let serverMessage;
    let serverDetails;
    if (error?.response?.status === 404) {
      window.location.href = URL.NOT_FOUND;
    }
    if (error?.response?.status >= 500) {
      window.location.href = URL.NOT_AVAILABLE;
    }
    if (Array.isArray(error?.response?.data)) {
      serverMessage = error.response.data
        .map((err: any) => err.message + '\n\n')
        .join(' ');
      serverDetails = error.response.data
        .map((err: any) => err.details)
        .join(' ');
    } else {
      serverMessage = error?.response?.data?.message;
      serverDetails = error?.response?.data?.details;
    }

    const message =
      serverMessage ||
      `Unknown error has occurred. 
      Please contact us.`;

    // do not show error if response is cancelled (e.g. on fast tabs switching)
    if (!axios.isCancel(error) && error?.response) {
      toast.error(<ErrorToast message={message} />, { autoClose: false });
    }
    if (serverDetails) {
      console.error(`API response error serverDetails: ${serverDetails}`);
    }
    return Promise.reject(error);
  },
);

export const useAxios = makeUseAxios({
  axios: axiosInstance,
});
