import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { NavLink, useLocation } from 'react-router-dom';
import { Container } from '@mui/material';
import { Button } from '@nordictrustee/nt-ui-library';
import StamdataLogo from 'assets/stamdataLogo.svg';
import * as URL from 'router/url';
import { LearningCenterSections } from 'screens/LearningCenterPage/TabMenu/LearningCenterSections';
import { Dialog } from 'components/Dialog/Dialog';
import { ExternalDomainLink } from 'components/ExternalDomainLink/ExternalDomainLink';
import { SectionContainer } from 'components/SectionContainer/SectionContainer';
import { conditionalCssClass } from 'utils/conditionalCssClass';
import { extractUrlFromPath } from 'utils/extractUrlFromPath';
import { EventActions } from 'utils/GoogleAnalitycsEvents/EventActions';
import { GA4eventTrack } from 'utils/GoogleAnalitycsEvents/eventTrackForGA';
import classes from './Footer.module.css';

export const Footer = () => {
  const [openCookieBar, setOpenCookieBar] = useState(false);
  const [openCookieInfoDialog, setOpenCookieInfoDialog] =
    useState<boolean>(false);
  const [cookies, setCookie] = useCookies(['ESGPlatformCookieConsent']);
  const location = useLocation();

  const handleCookie = () => {
    setCookie('ESGPlatformCookieConsent', 'true');
    setOpenCookieBar(false);
  };

  const handleClose = () => {
    setCookie('ESGPlatformCookieConsent', 'false');
    setOpenCookieBar(false);
  };

  const handleCloseDialog = () => {
    setOpenCookieInfoDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenCookieInfoDialog(true);
  };

  useEffect(() => {
    if (cookies.ESGPlatformCookieConsent === undefined) {
      setOpenCookieBar(true);
    }
  }, [cookies]);

  const isCompanyView = extractUrlFromPath(location.pathname) === URL.COMPANY;

  return (
    <SectionContainer
      className={`${classes.wrapper} ${conditionalCssClass(
        classes.bottomPadding,
        isCompanyView,
      )}`}
    >
      <Dialog
        title="PERSONAL DATA"
        onClose={handleCloseDialog}
        open={openCookieInfoDialog}
      >
        <p>
          All personal data provided by a User on the Nordic ESG will be handled
          in accordance with relevant data protection regulations from time to
          time, and in accordance with Nordic Trustee’s privacy statement
          available at{' '}
          <ExternalDomainLink
            title="https://nordictrustee.com/about/governance"
            href="https://nordictrustee.com/about/governance"
            className={classes.externalLink}
          />
        </p>
        <p>
          The personal data collected through Nordic ESG is only for internal
          use and will not be handed over to any external parties. Nordic
          Trustee only collects information that is required to fulfill the
          purpose of the actual service, however Nordic Trustee may use external
          technology providers (such as Hotjar and Google Analytics) in order to
          use data for better understanding of Nordic Trustee’s customers’ needs
          and to optimize the solution. Such providers may use cookies and other
          technologies to collect data on Nordic Trustee’s users’ behavior and
          their devices. This data does not contain any personally identifiable
          information and will never be used to identify individual users. You
          may opt-out of Hotjar’s tracking service by visiting{' '}
          <ExternalDomainLink
            title="How to stop Hotjar from collecting your data"
            href="https://help.hotjar.com/hc/en-us/articles/360002735873-How-to-Stop-Hotjar-From-Collecting-your-Data"
            className={classes.externalLink}
          />{' '}
          and opt-out of Google Analytics by downloading Google’s official
          browser add-on here:{' '}
          <ExternalDomainLink
            title="Google Analytics"
            href="https://tools.google.com/dlpage/gaoptout"
            className={classes.externalLink}
          />
          .
        </p>
        <p>
          More information about Nordic Trustee’s use of cookies can also be
          found at{' '}
          <ExternalDomainLink
            title="https://www.nordictrustee.com/privacy"
            href="https://www.nordictrustee.com/privacy"
            className={classes.externalLink}
          />
          .
        </p>
      </Dialog>
      {openCookieBar && (
        <div className={classes.cookieBar}>
          <Container
            className={`${classes.cookieContainer} body4Fluid`}
            disableGutters
            maxWidth="xl"
          >
            <p>
              Nordic ESG uses cookies to ensure that we give you the best
              experience on our website. More information{' '}
              <span className={classes.cookieLink} onClick={handleOpenDialog}>
                here
              </span>
              .
            </p>
            <div className={classes.footerButtonsWrapper}>
              <Button className={classes.declineButton} onClick={handleClose}>
                <span className="body4Fluid">DECLINE</span>
              </Button>
              <Button className={classes.acceptButton} onClick={handleCookie}>
                <span className="body4Fluid">ACCEPT</span>
              </Button>
            </div>
          </Container>
        </div>
      )}
      <div className={classes.container}>
        <div className={classes.logoWrapper}>
          <ExternalDomainLink
            title="Stamdata.com"
            href="https://stamdata.com"
            withChildren
          >
            <img
              className={classes.stamdataLogo}
              src={StamdataLogo}
              alt="StamdataLogo"
            />
          </ExternalDomainLink>
        </div>
        <div className={classes.textWrapper}>
          <p className={`${classes.text} body5Fluid`}>
            Feel free to explore our website, but be aware that by doing so,
            you're agreeing to our Terms of Use and accepting any associated
            risks. You're welcome to use the information on this site for
            non-commercial, non-competitive activities, such as evaluating
            companies' ESG performance. However, if you intend to use our data
            in a professional context, you'll need to obtain licensing rights.
            Please be advised that unauthorized scraping or systematic
            electronic downloading of our data is strictly prohibited. This
            platform was developed through a collaborative effort between
            Stamdata, Nordic Trustee, and prominent investors in the Nordic
            community.
          </p>
          <p className={classes.lastLine}>
            ©
            <ExternalDomainLink
              title="Stamdata"
              href="https://stamdata.com"
              className={classes.companyLink}
            />
            AS. All rights reserved.
          </p>
        </div>
        <div className={classes.linksContainer}>
          <NavLink
            className={`${classes.link} body5Fluid`}
            to={URL.GREETINGS_FROM_STAMDATA}
          >
            Greetings from Stamdata
          </NavLink>
          <a
            href="mailto:mail@nordicesg.com?subject=NordicESG: <SUBJECT>"
            className={classes.link}
            onClick={GA4eventTrack.bind(this, {
              action: EventActions.MAIL_TO_LINK_CLICK,
              label: 'Footer Contact',
            })}
          >
            Contact
          </a>
          <NavLink className={`${classes.link} body5Fluid`} to={URL.USER_TERMS}>
            User terms
          </NavLink>
          <NavLink
            className={`${classes.link} body5Fluid`}
            to={URL.DISCLAIMER_PAGE}
          >
            Disclaimer
          </NavLink>
          <NavLink
            className={`${classes.link} body5Fluid`}
            to={{
              pathname: `${URL.LEARNING_CENTER}/${LearningCenterSections.FAQ}`,
              state: 12,
            }}
          >
            Industry classifications
          </NavLink>
          <ExternalDomainLink
            title="About us"
            href="https://nordictrustee.com/"
            className={`${classes.link} body5Fluid`}
          />
        </div>
      </div>
    </SectionContainer>
  );
};
