import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import { ThemeProvider } from '@nordictrustee/nt-ui-library';
import { Routes } from 'router/routes';
import { theme } from 'themes/theme';
import { EnvironmentName } from 'utils/types/EnvironmentName';

export const App = () => {
  useEffect(() => {
    const { ENVIRONMENT_NAME, GOOGLE_ANALYTICS_ID, REACT_APP_HOTJAR_ID } =
      window._env_;
    if (ENVIRONMENT_NAME && ENVIRONMENT_NAME !== EnvironmentName.prod) {
      const favicon = document.getElementById('favicon');
      // favicon-test.ico | favicon-uat.ico | favicon-hotfix.ico | favicon-local.ico
      favicon?.setAttribute('href', `/favicon-${ENVIRONMENT_NAME}.ico`);
    }

    if (GOOGLE_ANALYTICS_ID === 'G-E91Z4NYZGP') {
      ReactGA.initialize(GOOGLE_ANALYTICS_ID);
      ReactGA.send({
        hitType: 'pageview',
        page: document.location.pathname,
        title: document.title,
      });
    }

    if (REACT_APP_HOTJAR_ID === '3715190') {
      const hotjarSnippetVersion = 6;
      hotjar.initialize(REACT_APP_HOTJAR_ID, hotjarSnippetVersion);
    }
  }, []);

  return (
    <ThemeProvider overrideTheme={theme}>
      <Routes />
    </ThemeProvider>
  );
};
